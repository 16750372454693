




























































































import {
  Component,
  Vue
} from 'vue-property-decorator';


import {
  Auth
} from '@/store/auth'
import {
  Core
} from '@/store/core'
import {
  User
} from '@/store/user'
import _ from 'lodash'

@Component({
  components: {

  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  }
})
export default class Home extends Vue {
  private checkNum:any = ''
  private checkedData:number = 0;
  private AGENCY_DATA:any = null;
  private currentId: any | null = null
  private user: any = {}
  private year: any = []
  private rates: any = []
  private dialog: boolean = false
  private rate: any = {}
  private form: any = {}
  private formUpdate: any = null
  private rateDatas: any = []
  private rateStatus: any = []
  private response: boolean = false

  private scoreAll:number = 0
  private scoreArr:any = [];
  public async created() {

    await this.run()

  }
  result:any = []
  private async run() {
    let loader = await this.$loading.show()
    this.response = false;
    this.currentId = this.$route.query.id
    this.user = await User.getUser();
    this.year = await Core.getHttp(`/api/ita/v2/year/${this.$route.query.year}/`)
    this.rates = await Core.getHttp(`/api/ita/v2/rate/${this.$route.query.year}/`)
    this.result = await Core.getHttp(`/api/ita/v1/rateresult/?agency=${this.$route.query.id}`)
    this.rateStatus = await Core.getHttp(`/api/ita/v1/ratestatus/`)
    this.AGENCY_DATA = await Core.getHttp(`api/ita/v2/agency/${this.currentId}/`)
    await this.generateTable()
    await this.generateScore();
    this.response = true;
    await loader.hide()
  }

  private async generateTable() {
    for (let i = 0; i < this.rates.length; i++) {
      // console.log(this.rates[i].id);
      let result: any = _.filter(this.result, {
        'rate': this.rates[i].id
      })
      this.rates[i].evaluate  = await this.getEvaluate(this.rates[i].id)
      this.rates[i].result = result

    }
  }

  private async getEvaluate(rateId:number){
    let data = await Core.getHttp(`/api/oit/v1/evaluateoit/?rate=${rateId}&agency=${this.currentId}`);
    return (data.length > 0)?data[0]:{}
  }
  private  async storeEvaluate(oit:any){
    if(oit.evaluate.rate_status){
      let form = {
        "score": oit.evaluate.score,
        "comment": (oit.evaluate.comment)?oit.evaluate.comment:'',
        "rate": oit.id,
        "rate_status": oit.evaluate.rate_status,
        "tester":  this.user.pk,
        "agency": Number(this.currentId)
      }
      let store = await Core.postHttp(`/api/oit/v1/evaluateoit/`,form)
      if(store.id){
        alert('ให้คะแนนสำเร็จแล้ว')
        await this.run()
      }
    }else{
      alert('กรุณาระบุสถานะการตรวจสอบ')
    }

  }
  private  async updateEvaluate(oit:any){
    if(oit.evaluate.rate_status){
      let form = {
        "id":oit.evaluate.id,
        "score": oit.evaluate.score,
        "comment": (oit.evaluate.comment)?oit.evaluate.comment:'',
        "rate": oit.id,
        "rate_status": oit.evaluate.rate_status,
        "tester":  this.user.pk,
        "agency": Number(this.currentId)
      }
      let store = await Core.putHttp(`/api/oit/v1/evaluateoit/${oit.evaluate.id}/`,form)
      if(store.id){
        alert('ให้คะแนนสำเร็จแล้ว')
        await this.run()
      }
    }else{
      alert('กรุณาระบุสถานะการตรวจสอบ')
    }

  }

  public async openDialog(rate: any) {
    this.rate = rate
    this.form.rate = rate.id
    this.form.name = rate.name
    await this.loadRateData(rate);
    this.dialog = true;
  }

  public async closeDialog() {
    this.rate = null
    this.form = {}
    this.dialog = false;
  }
  resultResponse:boolean = false
  private async loadRateData(rate:any) {
    this.rate = await Core.getHttp(`/api/ita/v1/rate/${rate.id}/`)
    this.form.rate = rate.id
    this.form.name = rate.name
    this.rateDatas = await Core.getHttp(`/api/ita/v1/rateresult/?agency=${this.$route.query.id}&rate=${this.rate.id}`)
    console.log(this.rate)
    this.resultResponse = true;
  }

  private async getResultByUser(i: number) {
    return await Core.putHttp(`/api/ita/v2/rateresult/`, {
      "agency": this.currentId,
      "rate": this.rates[i].id
    })
  }

  private async updateData(data: any) {
    data.user = data.user.pk
    data.user_passing = data.user_passing.pk
    data.tester = this.user.pk
    let update = await Core.putHttp(`/api/ita/v1/rateresult/${data.id}/`, data)
    if (update.id) {
      this.form = {}
      await this.run()
      await this.loadRateData(this.rate)

    }
  }

  private passingAllCheckTrue(datas:any[]){

    if(datas.length > 0){
      let passed = _.filter(datas,{passing:true})
      //console.log(passed.length , datas);
      return (passed.length >0) ? true:false;
    }else{
      return false;
    }

  }


  private openLink(url: string) {
    window.open(url, '_blank');
  }
  getTest(result:any){
    let res = _.filter(result,{'rate':4})
    return res.length;
  }

  getPassingTest(result:any){
    let res = _.filter(result,function(o) { return o.tester != null; })
    return res.length;
  }
  getScoreAll(result:any){
    let score = _.meanBy(result, (p:any) => p.score);
    return isNaN(score)?0:score
  }

  async generateScore(){
    let listView = [];
    for (let index = 0 ; index < this.rates.length ; index++){

      let score = _.meanBy(this.rates[index].result, (p:any) => p.score);
      listView.push({
        "name":this.rates[index].name,
        "score":score,
        "order":index
      })

    }
    let scoreTmp = _.meanBy(listView, (p:any) => p.score)
    let response = {
      "list":listView,
      "score_avg":scoreTmp,
      "score60":((scoreTmp/100)*60).toFixed(2)
    }
    console.log('[dd]',response)

  }

  numChoice:any = []
  async insertNum(){
    let data = _.includes(this.numChoice,Number(this.checkNum))
    if(!data){
      this.numChoice.push(Number(this.checkNum))
      this.checkNum = ''
    }else{
      this.checkNum = ''
      alert('ใส่ข้อนี้แล้ว')
    }
  }
  checkNumData(num:number){
    if(this.numChoice.length > 0){
      return _.includes(this.numChoice,num)
    }else{
      return true
    }
  }

  checkDataTest(check:boolean){
    console.log(check)
    if(this.checkedData == 0){
      return true;
    }else if(this.checkedData == 1){
      return check
    }else if(this.checkedData == 2){
      return !check
    }else{
      return true;
    }
  }
}
